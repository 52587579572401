
const Showcase = () => {
  return (
    <div className='black'>
      <p className='payoff'>Get your personal <mark>GitHub-Since</mark> card!<br />
        Create your own!

        <br />👇👇👇
      </p>
    </div>
  )
}

export default Showcase
